import { APP_EDITION_SEPHORA } from '../../../constants/constants'
import AccordionConcerns from '../../account/congratulationsAB/C/results/accordion-concerns'
import Concerns from '../../account/congratulationsAB/C/results/concerns'
import Environment from '../../account/congratulationsAB/C/results/environment'
import Lifestyle from '../../account/congratulationsAB/C/results/lifestyle'
import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { appEditionSelector } from '../../../utils/selectors'
import { createProvenTheme } from '../../../styles/theme-proven'
import { ThemeProvider } from '@mui/material/styles'
import useAuth from 'hooks/use-auth'
import useProductsResultsData from '../../../hooks/useProductsResultsData'

const themeProven = createProvenTheme()

function ConcernsEnvironmentLifestyleWrapper({
  enforceAccordionVersionForConcernsSection,
  skinConcernsDescription,
  environmentDescription,
  lifeStyleDescription
}) {
  const isCongratulationsPage = useRouteMatch('/account/congratulations')
  const { isAuthenticated } = useAuth()
  const appEdition = useSelector(appEditionSelector)

  const { skin, lifestyle, waterHardness, airQuality, climate, environment } =
    useProductsResultsData()

  if (!isAuthenticated) return null

  return (
    <ThemeProvider theme={themeProven}>
      {skin &&
        skin.length > 0 &&
        ((enforceAccordionVersionForConcernsSection && !isCongratulationsPage) ||
        appEdition === APP_EDITION_SEPHORA ? (
          <AccordionConcerns skin={skin} skinConcernsDescription={skinConcernsDescription} />
        ) : (
          <Concerns skin={skin} skinConcernsDescription={skinConcernsDescription} />
        ))}
      <Environment
        environmentDescription={environmentDescription}
        hideConcerns={!skin?.length}
        climate={climate}
        airQuality={airQuality}
        waterHardness={waterHardness}
        environment={environment}
      />
      <Lifestyle lifestyle={lifestyle} lifeStyleDescription={lifeStyleDescription} />
    </ThemeProvider>
  )
}

export default ConcernsEnvironmentLifestyleWrapper
