import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { createProvenTheme } from '../../../styles/theme-proven'
import Countdown from 'react-countdown'
import { onDesktop } from '../../../styles/theme-proven/responsive'

const themeProven = createProvenTheme()

function CountdownTimer({ date }) {
  const counter = {
    backgroundColor: 'rgba(149, 200, 216, 0.6)',
    borderRadius: 0.5,
    color: `${themeProven.palette.secondary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '25px',
    height: '24px',
    padding: '1px',
    fontSize: '16px',
    [onDesktop]: {
      fontSize: '18px'
    }
  }

  const subtext = {
    color: `${themeProven.palette.primary.current}`,
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '24px',
    fontSize: '10px',
    [onDesktop]: {
      fontSize: '11px'
    }
  }

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Typography variant="title2"> The wait is over! </Typography>
    } else {
      // Render countdown
      return (
        <Stack direction="row">
          <Stack sx={{ marginRight: `${themeProven.spacing(1.5)}` }}>
            <Typography variant="title2" align="center" sx={counter}>
              {days}
            </Typography>
            <Typography variant="text" sx={subtext}>
              {days === 1 ? 'Day' : 'Days'}
            </Typography>
          </Stack>

          <Stack sx={{ margin: `0 ${themeProven.spacing(0.3)}`, justifyContent: 'center' }}>
            <Typography variant="title2" sx={counter}>
              {hours}
            </Typography>
            <Typography variant="text" sx={subtext}>
              {hours === 1 ? 'Hour' : 'Hours'}
            </Typography>
          </Stack>

          <Typography
            variant="title2"
            color="secondary.main"
            sx={{ alignItems: 'center', lineHeight: '120%' }}
          >
            :
          </Typography>

          <Stack sx={{ margin: `0 ${themeProven.spacing(0.3)}` }}>
            <Typography variant="title2" sx={counter}>
              {minutes}
            </Typography>
            <Typography variant="text" sx={subtext}>
              {minutes === 1 ? 'Min' : 'Mins'}
            </Typography>
          </Stack>

          <Typography
            variant="title2"
            color="secondary.main"
            sx={{ alignItems: 'center', lineHeight: '120%' }}
          >
            :
          </Typography>

          <Stack sx={{ margin: `0 ${themeProven.spacing(0.3)}` }}>
            <Typography variant="title2" sx={counter}>
              {seconds}
            </Typography>
            <Typography variant="text" sx={subtext}>
              Sec
            </Typography>
          </Stack>
        </Stack>
      )
    }
  }

  return <Countdown date={new Date(date)} renderer={renderer} />
}

export default CountdownTimer
