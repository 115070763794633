import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import useIsInViewport from '../../../hooks/useIsInViewport'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  tickerColumn: {
    position: 'absolute',
    height: '1000%',
    bottom: 0
  }
}))

function formatForDisplay(number = 0) {
  return number.toString().padStart(2, '0').split('').reverse()
}

function DecimalColumn() {
  return (
    <div>
      <span>.</span>
    </div>
  )
}

function NumberColumn({ digit }) {
  const [position, setPosition] = useState(0)
  const columnContainer = useRef()
  const classes = useStyles()

  const setColumnToNumber = number => {
    setPosition(columnContainer.current.clientHeight * parseInt(number, 10))
  }

  useEffect(() => setColumnToNumber(digit), [digit])

  return (
    <Box sx={{ position: 'relative' }} ref={columnContainer}>
      <Box
        component={motion.div}
        animate={{ y: position, animationDelay: '2s' }}
        className={classes.tickerColumn}
      >
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map(num => (
          <Box key={num} sx={{ height: '10%' }}>
            <span>{num}</span>
          </Box>
        ))}
      </Box>
      <Box component="span" sx={{ visibility: 'hidden' }}>
        0
      </Box>
    </Box>
  )
}

export default function AnimatedNumber({ value, parentRef }) {
  const [currentValue, setCurrentValue] = useState(0)
  const previousNumber = 0
  const isInViewPort = useIsInViewport(parentRef)
  const numArray = formatForDisplay(currentValue)

  useEffect(() => {
    if (isInViewPort && currentValue < value) {
      setTimeout(() => {
        setCurrentValue(v => {
          return Math.min(value, v + 4)
        })
      }, 20)
    }
  }, [isInViewPort, value, currentValue])

  let delta = null
  if (isInViewPort > previousNumber) delta = 'increase'
  if (isInViewPort < previousNumber) delta = 'decrease'

  return (
    <Box
      component={motion.div}
      layout
      sx={{
        height: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row-reverse',
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      {numArray.map((number, index) =>
        number === '.' ? (
          <DecimalColumn key={index} />
        ) : (
          <NumberColumn key={index} digit={number} delta={delta} />
        )
      )}
    </Box>
  )
}
