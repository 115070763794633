import React from 'react'
import Slider from 'react-slick'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { onDesktop } from '../../../../styles/theme-proven/responsive'
import { createProvenTheme } from '../../../../styles/theme-proven'
import ProvenIcon from '../../proven-icon'
import { ARROW_VERSION_STYLE_A, ARROW_VERSION_STYLE_B } from '../../../../constants/constants'

import { TYPE_SYSTEM } from '../../../../constants/icons'
import { customColors } from '../../../../styles/theme-proven/colors'

const themeProven = createProvenTheme()
const useStyles = makeStyles(theme => ({
  slider: {
    marginBottom: '38px',
    '& .slick-list': {
      margin: '0 30px'
    }
  },
  nextPrevArrow: {
    display: 'block',
    boxSizing: 'border-box',
    height: '12px',
    width: '12px',
    transform: 'rotate(-45deg)',
    top: '84vw',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: '2',
    [theme.breakpoints.up('sm')]: {
      height: '19px',
      width: '19px'
    },
    [onDesktop]: {
      top: '50%'
    }
  },
  nextArrow: {
    borderRight: `2px solid ${themeProven.palette.gray.elysian}`,
    borderBottom: `2px solid ${themeProven.palette.gray.elysian}`,
    right: '8px',

    [theme.breakpoints.up('md')]: {
      right: '15px'
    }
  },
  prevArrow: {
    borderLeft: `2px solid ${themeProven.palette.gray.elysian}`,
    borderTop: `2px solid ${themeProven.palette.gray.elysian}`,
    left: '8px',

    [theme.breakpoints.up('md')]: {
      left: '15px'
    }
  }
}))

export default function BeforeAfterSlide({ children, arrowVersionStyle = ARROW_VERSION_STYLE_A }) {
  const arrowVersionStyleAClasses = useStyles()

  let NextArrow, PrevArrow
  if (arrowVersionStyle === ARROW_VERSION_STYLE_B) {
    let leftStyle, rightStyle
    const arrowStyleB = {
      bottom: '75px',
      width: '32px',
      height: '32px',
      padding: '8px',
      borderStyle: 'solid',
      borderColor: 'gray.middleGray',
      borderRadius: '50%',
      borderWidth: '1px',
      position: 'absolute',
      cursor: 'pointer',
      zIndex: '2'
    }
    leftStyle = {
      ...arrowStyleB,
      left: 'calc(50% - 87px)',
      [onDesktop]: { left: 'calc(50% - 127px)' }
    }
    rightStyle = {
      ...arrowStyleB,
      right: 'calc(50% - 94px)',
      [onDesktop]: { right: 'calc(50% - 134px)' }
    }

    NextArrow = ({ onClick }) => {
      return (
        <ProvenIcon
          // Basically the same as .slick-next class CSS, but without the transparency.
          sx={rightStyle}
          type={TYPE_SYSTEM}
          name="arrow-right"
          size="medium"
          color={customColors.gray.elysian}
          onClick={onClick}
        />
      )
    }
    PrevArrow = ({ onClick }) => {
      return (
        <ProvenIcon
          // Basically the same as .slick-prev class CSS, but without the transparency.
          sx={leftStyle}
          type={TYPE_SYSTEM}
          name="arrow-left"
          size="medium"
          color={customColors.gray.elysian}
          onClick={onClick}
        />
      )
    }
  } else {
    NextArrow = props => {
      const { onClick } = props
      return (
        <div
          className={`${arrowVersionStyleAClasses.nextPrevArrow} ${arrowVersionStyleAClasses.nextArrow}`}
          onClick={onClick}
        />
      )
    }

    PrevArrow = props => {
      const { onClick } = props
      return (
        <div
          className={`${arrowVersionStyleAClasses.nextPrevArrow} ${arrowVersionStyleAClasses.prevArrow}`}
          onClick={onClick}
        />
      )
    }
  }
  const sliderSettings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: true,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          centerPadding: '160px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <Box
      sx={{
        width: '100%',
        // ml: { xs: -2, md: -8, lg: '-150px', xxl: '-12.5%' },
        '& .slick-list': {
          paddingBottom: '126px !important',
          paddingTop: '24px !important',
          [onDesktop]: {
            paddingBottom: '130px !important',
            paddingTop: '48px !important'
          }
        },
        '& .slick-dots': {
          bottom: '80px',
          '& li': {
            width: '13px',
            margin: 0,
            '& button:before': {
              opacity: 0.4300000071525574,
              color: '#256376'
            }
          },
          '& li.slick-active button:before': {
            opacity: 1
          }
        },
        '& .slick-slider': {
          paddingLeft: '0px !important'
        }
      }}
    >
      <Slider {...sliderSettings}>{children}</Slider>
    </Box>
  )
}
