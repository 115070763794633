import React from 'react'
import { Box } from '@mui/material'
import GenericBuilderSymbol from '../generic-builder-symbol'

function BeforeAfterCarousel() {
  return (
    <Box sx={{ width: '100%' }}>
      <GenericBuilderSymbol
        symbolName="Clinically Tested for Efficacy"
        wrapperStyle={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .builder-block': {
            '& span.builder-text': {
              textAlign: 'center',
              '& p': {
                margin: 0
              }
            }
          }
        }}
      />
    </Box>
  )
}

export default BeforeAfterCarousel
