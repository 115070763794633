import React from 'react'
import propTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import { Image as BuilderImage } from 'components/builder/blocks/shared/Image'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import ProvenHexShape from '../../proven-hex-shape'
import { onDesktop } from '../../../../styles/theme-proven/responsive'

const useStyles = makeStyles(theme => ({
  stretch: {
    height: '100%'
  },
  image: {
    aspectRatio: '1.5/1',
    margin: 0,
    padding: 0,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    opacity: 1,
    transform: 'scale(0.88)',
    transition: 'all .3s ease',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      background: 'none'
    },
    '.slick-center &': {
      opacity: 1,
      aspectRatio: '1.75/1',
      transform: 'scale(1)',
      boxShadow: 'none'
    }
  },
  widget: {
    display: 'flex',
    gap: '4px',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      gap: '8px'
    },
    '.slick-center &': {
      display: 'flex'
    },
    '& img': {
      height: '100%',
      width: '100%'
    }
  },
  imageAfter: {
    display: 'none',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    },
    '.slick-center &': {
      display: 'none'
    }
  },
  targetImage: {
    transform: 'scale(1)',
    height: '100%'
  }
}))

function Image({ before, after, singleSlide, withoutLazy = false, hideSkinMarker = false }) {
  const isDesktop = useMediaQuery(onDesktop)
  const classes = useStyles()
  const markerBoxSx = {
    width: ['100px', '100px', '160px'],
    height: ['100px', '100px', '160px'],
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)  rotate(20deg)'
  }
  const sideImageSx = {
    flex: 1,
    height: '100%',
    position: 'relative',
    borderRadius: singleSlide ? 2 : 'unset',
    overflow: singleSlide ? 'hidden' : 'initial'
  }
  return (
    <figure className={classes.image}>
      <Box className={classes.widget}>
        <Box sx={sideImageSx}>
          <BuilderImage image={before} altText="Image before" aspectRatio={1} lazy={!withoutLazy} />
          {!hideSkinMarker && (
            <Box sx={markerBoxSx}>
              <ProvenHexShape
                zIndex="10"
                color="white"
                opacity="1"
                scale={isDesktop ? 0.9 : 0.56}
              />
            </Box>
          )}
          <Typography
            variant="footnoteBold"
            color="white"
            sx={{ zIndex: 20, position: 'absolute', left: 16, top: 16 }}
          >
            Before
          </Typography>
        </Box>
        <Box sx={sideImageSx}>
          <BuilderImage image={after} altText="Image after" aspectRatio={1} lazy={!withoutLazy} />
          {!hideSkinMarker && (
            <Box sx={markerBoxSx}>
              <ProvenHexShape
                zIndex="10"
                color="white"
                opacity="1"
                scale={isDesktop ? 0.9 : 0.56}
              />
            </Box>
          )}
          <Typography
            variant="footnoteBold"
            color="white"
            sx={{ zIndex: 20, position: 'absolute', right: 16, top: 16 }}
          >
            After
          </Typography>
        </Box>
      </Box>
      <div className={classes.imageAfter}>
        <Box sx={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}>
          <Grid
            className={classes.stretch}
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={6} pr={1}>
              <Box
                sx={{
                  borderRadius: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: {
                      sm: '260px',
                      lg: '320px'
                    }
                  }}
                >
                  <BuilderImage lazy={!withoutLazy} image={before} aspectRatio={1} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} pl={1}>
              <Box
                sx={{
                  borderRadius: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: {
                      sm: '260px',
                      lg: '320px'
                    }
                  }}
                >
                  <BuilderImage lazy={!withoutLazy} image={after} aspectRatio={1} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </figure>
  )
}

Image.propTypes = {
  before: propTypes.string,
  after: propTypes.string
}

export default Image
